<template>
  <div class="flex flex-col">
    <div class="mb-1 flex justify-end">
      <div
          class="d-flex smallGap text-secondaryColor align-items-center"
          role="button"
          @click="$router.go(-1)"
      >
        <mdicon :width="25" :height="25" name="arrow-left-thick"/>
        <p class="mb-0" v-text="$t(locale.back)"/>
      </div>
    </div>
    <b-card>
      <b-row align-h="center">
        <b-col cols="6">
          <span class="flex gap-3 items-center">
            <p class="mb-0 h4 text-gray-800">Redigera produkt</p>
            <p class="mb-0 h4 text-gray-800">
              <strong>{{ singleArticle.article_number }} </strong>
            </p>
          </span>
        </b-col>
        <b-col cols="6" class="d-flex justify-content-end">
          <div class="d-flex smallGap text-secondaryColor align-items-center">
            <LittleTabs
                :tabs="tabs"
                :switchTabs="switchTabs"
                containerStyle="justify-content-start"
                :isToggleAble="singleArticle.type != 'STOCK'"
            />
          </div>
        </b-col>
      </b-row>
    </b-card>
    <div v-show="tabSwitch === 1">
      <b-row>
        <b-col cols="12" lg="12">
          <SecondaryCard :title="$t(locale.product)" class="">
            <b-row>
              <b-col cols="12" lg="8">
                <b-row>
                  <b-col cols="12" lg="4" v-if="hidden_fields.article_number">
                    <b-form-group
                        :label="$t(locale.itemID)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          name="login-email"
                          v-model="singleArticle.article_number"
                          :placeholder="$t(locale.itemID)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" v-if="hidden_fields.description">
                    <b-form-group :label="$t(locale.name)" label-for="input-1">
                      <b-form-input
                          size="sm"
                          name="login-email"
                          v-model="singleArticle.description"
                          :placeholder="$t(locale.name)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" v-if="hidden_fields.default_stock_point">
                    <b-form-group
                        :label="$t(locale.orderPoint)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          name="login-email"
                          v-model="singleArticle.default_stock_point"
                          :placeholder="$t(locale.orderPoint)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" v-if="hidden_fields.ean">
                    <b-form-group
                        :label="$t(locale.ean)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          name="login-email"
                          v-model="singleArticle.ean"
                          :placeholder="$t(locale.ean)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" v-if="hidden_fields.supplier">
                    <b-form-group
                        :label="$t(locale.supplier)"
                        label-for="input-1"
                    >
                      <v-select
                          v-model="supplier"
                          :placeholder="$t(locale.supplier)"
                          :options="supplierResults"
                          @option:selected="
                              computeSupplier($event, 'supplier_number')
                            "
                          class="select-size-sm resource-selector d-inline-block w-full"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" v-if="hidden_fields.manufacturer">
                    <b-form-group
                        :label="$t(locale.manufacturer)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          name="login-email"
                          v-model="singleArticle.manufacturer"
                          :placeholder="$t(locale.manufacturer)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" v-if="hidden_fields.manufacturer_article_number">
                    <b-form-group
                        :label="$t(locale.manufacturerNumber)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          name="login-email"
                          v-model="singleArticle.manufacturer_article_number"
                          :placeholder="$t(locale.manufacturerNumber)"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" lg="4" v-if="hidden_fields.note">
                    <b-form-group :label="$t(locale.notes)" label-for="input-1">
                      <b-form-textarea
                          size="lg"
                          v-model="singleArticle.note"
                          :placeholder="$t(locale.notes)"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

              </b-col>
              <b-col cols="12" lg="4">
                <b-row>
                  <b-col cols="12" lg="6" v-if="hidden_fields.active">
                    <b-form-group
                        :label="$t(locale.active)"
                        label-for="input-1"
                    >
                      <LittleTabs
                          :tabs="[
                          {
                            label: 'CustomersCardEdit.yes',
                            active: true,
                            value: true,
                          },
                          {
                            label: 'CustomersCardEdit.no',
                            active: false,
                            value: false,
                          },
                        ]"
                          :defaultValue="true"
                          v-model="singleArticle.active"
                          buttonSize="sm"
                          containerStyle="justify-content-start"
                          btnClass="tabWrapperSmall2"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6" v-if="hidden_fields.unit">
                    <b-form-group :label="$t(locale.unit)" label-for="input-1">
                      <v-select
                          v-model="unitValue"
                          :placeholder="$t(locale.unit)"
                          :options="unitResults"
                          @option:selected="updateUnitCode"
                          class="select-size-sm resource-selector d-inline-block w-full"
                      />
                      <!-- <b-form-input
                                            size="sm"
                                                name="login-email"
                                                v-model="singleArticle.unit"
                                                :placeholder="
                                                    $t(locale.unit)
                                                "
                                            /> -->
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6" v-if="hidden_fields.type">
                    <b-form-group
                        :label="$t(locale.itemType)"
                        label-for="input-1"
                    >
                      <LittleTabs
                          :tabs="[
                          {
                            label: 'articleSectionCard.stock',
                            active: true,
                            value: 'STOCK',
                          },
                          {
                            label: 'articleSectionCard.service',
                            active: false,
                            value: 'SERVICE',
                          },
                        ]"
                          :defaultValue="true"
                          v-model="singleArticle.type"
                          buttonSize="sm"
                          containerStyle="justify-content-start"
                          btnClass="tabWrapperSmall2"
                          :isToggleAble="singleArticle.type == 'STOCK'"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6" v-if="hidden_fields.stock_goods">
                    <b-form-group
                        :label="$t(locale.stockItem)"
                        label-for="input-1"
                    >
                      <LittleTabs
                          :tabs="[
                          {
                            label: 'CustomersCardEdit.yes',
                            active: true,
                            value: true,
                          },
                          {
                            label: 'CustomersCardEdit.no',
                            active: false,
                            value: false,
                          },
                        ]"
                          :defaultValue="true"
                          v-model="singleArticle.stock_goods"
                          buttonSize="sm"
                          containerStyle="justify-content-start"
                          btnClass="tabWrapperSmall2"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6" v-if="hidden_fields.webshop_article">
                    <b-form-group
                        :label="$t(locale.externalWebshop)"
                        label-for="input-1"
                    >
                      <LittleTabs
                          :tabs="[
                          {
                            label: 'CustomersCardEdit.yes',
                            active: true,
                            value: true,
                          },
                          {
                            label: 'CustomersCardEdit.no',
                            active: false,
                            value: false,
                          },
                        ]"
                          :defaultValue="true"
                          v-model="singleArticle.webshop_article"
                          buttonSize="sm"
                          containerStyle="justify-content-start"
                          btnClass="tabWrapperSmall2"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6" v-if="hidden_fields.deleted">
                    <b-form-group
                        :label="$t('Discountinied')"
                        label-for="input-1"
                    >
                      <LittleTabs
                          :tabs="[
                          {
                            label: 'CustomersCardEdit.yes',
                            active: true,
                            value: true,
                          },
                          {
                            label: 'CustomersCardEdit.no',
                            active: false,
                            value: false,
                          },
                        ]"
                          :defaultValue="true"
                          v-model="singleArticle.expired"
                          buttonSize="sm"
                          containerStyle="justify-content-start"
                          btnClass="tabWrapperSmall2"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </SecondaryCard>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="8">
          <SecondaryCard :title="$t(locale.price)" class="mt-2">
            <b-row>
              <b-col cols="12">
                <table class="table w-full z-50 relative mb-7">
                  <thead class="">
                  <tr>
                    <!-- <th class="text-left whitespace-nowrap p-2 md:p-0 w-32"><span class="text-xs text-gray-400">Article #</span></th> -->
                    <th class="text-left whitespace-nowrap p-2 md:p-0 w-32">
                        <span class="text-xs text-gray-400">{{
                            locale.priceList
                          }}</span>
                    </th>
                    <th class="text-left whitespace-nowrap p-2 md:p-0">
                        <span class="text-xs text-gray-400">{{
                            locale.description
                          }}</span>
                    </th>
                    <th class="text-left whitespace-nowrap p-2 md:p-0">
                        <span class="text-xs text-gray-400">{{
                            locale.price
                          }}</span>
                    </th>
                    <th class="text-left whitespace-nowrap p-2 md:p-0">
                      <span class="text-xs text-gray-400">QTY</span>
                    </th>
                    <th class="text-left whitespace-nowrap p-2 md:p-0">
                        <span class="text-xs text-gray-400">{{
                            locale.margin
                          }}</span>
                    </th>
                    <th class="text-left whitespace-nowrap p-2 md:p-0">
                      <span class="text-xs text-gray-400">Action</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <ArticlePriceRow
                      @remove-article-price="removeArticlePrice"
                      :itemData="item"
                      :index="i"
                      :article_instance="singleArticle"
                      v-for="(item, i) in articlePrices"
                      :key="i"
                  />
                  <tr>
                    <!-- <td>
                                              <div
                                                  class="d-flex justify-content-center w-full"
                                              >
                                                  <b-form-input
                                                  size="sm"
                                                      class="w-full"
                                                      name="login-email"
                                                      v-model="articlePriceForm.article_number"
                                                      :placeholder="
                                                          ''
                                                      "
                                                  />
                                              </div>
                                          </td> -->
                    <td>
                      <div class="d-flex justify-content-center w-full">
                        <v-select
                            class="w-full select-size-sm"
                            v-model="articlePriceForm.price_list"
                            label="code"
                            @option:selected="changePricelist"
                            :filterable="false"
                            :options="pricelistResults"
                            @search="searchPricelist"
                        >
                          <template slot="no-options">
                            Sök...
                          </template>
                          <template slot="option" slot-scope="option">
                            <div class="d-center">
                              {{ option.code + " " + option.description }}
                            </div>
                          </template>
                          <template
                              slot="selected-option"
                              slot-scope="option"
                          >
                            <div class="selected d-center">
                              {{ option.code }}
                            </div>
                          </template>
                        </v-select>
                        <!-- <v-select
                                                      :options="pricelistResults"
                                                      v-model="articlePriceForm.price_list"
                                                      @option:selected="computeSelect($event, 'price_list')"
                                                      class="resource-selector d-inline-block"
                                                  /> -->
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-center w-full">
                        <b-form-input
                            size="sm"
                            class="w-full"
                            name="login-email"
                            v-model="articlePriceForm.description"
                            :placeholder="''"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-center w-full">
                        <b-form-input
                            size="sm"
                            v-model="articlePriceForm.price"
                            class="w-full"
                            name="login-email"
                            :placeholder="''"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-center w-full">
                        <b-form-input
                            size="sm"
                            v-model="articlePriceForm.from_quantity"
                            class="w-full"
                            name="login-email"
                            :placeholder="''"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-center w-full">
                        <!-- <b-form-input
                                                  size="sm"
                                                      v-model="articlePriceForm.margin"
                                                      class="w-full"
                                                      name="login-email"
                                                      :placeholder="
                                                          ''
                                                      "
                                                  /> -->
                        <span>{{ margin }}</span>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  </tbody>
                </table>
              </b-col>

              <b-col cols="4">
                <b-button
                    :variant="'primary'"
                    :disabled="priceAdding"
                    type="submit"
                    @click="addArticlePrice"
                    v-text="priceAdding ? $t('Message.loading') : 'Add Pricelist'"
                />
              </b-col>
            </b-row>
          </SecondaryCard>
        </b-col>
        <b-col cols="12" lg="4">
          <SecondaryCard :title="$t(locale.account)" class="mt-2">
            <b-row>
              <b-col cols="12" lg="6" v-if="hidden_fields.sales_account">
                <b-form-group
                    :label="$t(locale.salesAccount)"
                    label-for="input-1"
                >
                  <v-select
                      class="w-full select-size-sm"
                      v-model="singleArticle.sales_account"
                      label="number"
                      @option:selected="changeAccounts($event, 'sales_account')"
                      :filterable="false"
                      :options="accountResults"
                      @search="searchAccounts"
                  >
                    <template slot="no-options"> Sök...</template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.number + " " + option.description }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.number }}
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="6" v-if="hidden_fields.vat">
                <b-form-group :label="$t(locale.vat)" label-for="input-1">
                  <b-form-input
                      size="sm"
                      name="login-email"
                      readonly
                      disabled
                      v-model="singleArticle.vat"
                      :placeholder="''"
                  />
                </b-form-group>
              </b-col>
              <!-- <b-col cols="12" lg="6">
                                <b-form-group
                                        :label="
                                            $t(locale.salesSEReverseTax)
                                        "
                                        label-for="input-1"
                                    >
                                    <v-select class="w-full" v-model="singleArticle" label="number" @option:selected="changeAccounts($event)" :filterable="false" :options="accountResults" @search="searchAccounts">
                                        <template slot="no-options">
                                            Sök...
                                        </template>
                                        <template slot="option" slot-scope="option">
                                            <div class="d-center">
                                                {{ option.number +' '+ option.description }}
                                            </div>
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            <div class="selected d-center">
                                                {{ option.number }}
                                            </div>
                                        </template>
                                    </v-select>

                                </b-form-group>
                            </b-col> -->
              <b-col cols="12" lg="6" v-if="hidden_fields.eu_account">
                <b-form-group :label="'Sales EU account'" label-for="input-1">
                  <v-select
                      class="w-full select-size-sm"
                      v-model="singleArticle.eu_account"
                      label="number"
                      @option:selected="changeAccounts($event, 'eu_account')"
                      :filterable="false"
                      :options="accountResults"
                      @search="searchAccounts"
                  >
                    <template slot="no-options"> Sök...</template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.number + " " + option.description }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.number }}
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="6" v-if="hidden_fields.euvat_account">
                <b-form-group
                    :label="$t(locale.salesEUVatLiable)"
                    label-for="input-1"
                >
                  <v-select
                      class="w-full select-size-sm"
                      v-model="singleArticle.euvat_account"
                      label="number"
                      @option:selected="changeAccounts($event, 'euvat_account')"
                      :filterable="false"
                      :options="accountResults"
                      @search="searchAccounts"
                  >
                    <template slot="no-options"> Sök...</template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.number + " " + option.description }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.number }}
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="6" v-if="hidden_fields.sales_account_export">
                <b-form-group
                    :label="$t(locale.salesExport)"
                    label-for="input-1"
                >
                  <v-select
                      class="w-full select-size-sm"
                      v-model="singleArticle.export_account"
                      label="number"
                      @option:selected="changeAccounts($event, 'export_account')"
                      :filterable="false"
                      :options="accountResults"
                      @search="searchAccounts"
                  >
                    <template slot="no-options"> Sök...</template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.number + " " + option.description }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.number }}
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="6" v-if="hidden_fields.purchase_account">
                <b-form-group
                    :label="$t(locale.purchaseAccount)"
                    label-for="input-1"
                >
                  <v-select
                      class="w-full select-size-sm"
                      v-model="singleArticle.purchase_account"
                      label="number"
                      @option:selected="
                      changeAccounts($event, 'purchase_account')
                    "
                      :filterable="false"
                      :options="accountResults"
                      @search="searchAccounts"
                  >
                    <template slot="no-options"> Sök...</template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.number + " " + option.description }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.number }}
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </SecondaryCard>
        </b-col>
      </b-row>
    </div>
    <div v-show="tabSwitch === 2">
      <b-row>
        <b-col cols="12" lg="12">
          <SecondaryCard :title="$t(locale.stock)" class="mt-2">
            <b-row>
              <b-col cols="12" lg="8">
                <b-row>
                  <b-col cols="12" lg="4" v-if="hidden_fields.cost_calculation_method">
                    <b-form-group
                        :label="$t(locale.costCalculation)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          name="login-email"
                          v-model="singleArticle.cost_calculation_method"
                          :placeholder="$t(locale.costCalculation)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" v-if="hidden_fields.quantity_in_stock">
                    <b-form-group
                        :label="$t(locale.inWarehouse)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          name="login-email"
                          type="number"
                          min="1"
                          v-model="singleArticle.quantity_in_stock"
                          :placeholder="$t(locale.inWarehouse)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" v-if="hidden_fields.purchase_price">
                    <b-form-group
                        :label="$t(locale.purchasePrice)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          name="login-email"
                          v-model="singleArticle.purchase_price"
                          :placeholder="$t(locale.purchasePrice)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" v-if="hidden_fields.stock_value">
                    <b-form-group
                        :label="$t(locale.stockValue)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          readonly
                          disabled
                          v-model="singleArticle.stock_value"
                          :placeholder="$t(locale.stockValue)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" v-if="hidden_fields.reserved_quantity">
                    <b-form-group
                        :label="$t(locale.reserveQty)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          readonly
                          disabled
                          type="number"
                          min="1"
                          v-model="singleArticle.reserved_quantity"
                          :placeholder="$t(locale.reserveQty)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" v-if="hidden_fields.default_stock_point">
                    <b-form-group
                        :label="$t(locale.stockPoint)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          name="login-email"
                          v-model="singleArticle.default_stock_point"
                          :placeholder="$t(locale.stockPoint)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <b-form-group
                        :label="$t(locale.availableAmt)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          name="login-email"
                          :value="
                          singleArticle.quantity_in_stock -
                          singleArticle.reserved_quantity
                        "
                          readonly
                          disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" lg="4">
                <b-row class="items-center">
                  <b-col cols="12" lg="4" v-if="hidden_fields.width">
                    <b-form-group
                        :label="$t(locale.widthmm)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          name="login-email"
                          type="number"
                          min="1"
                          v-model="singleArticle.width"
                          :placeholder="$t(locale.name)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" v-if="hidden_fields.height">
                    <b-form-group
                        :label="$t(locale.heightmm)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          name="login-email"
                          type="number"
                          min="1"
                          v-model="singleArticle.height"
                          :placeholder="$t(locale.name)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" v-if="hidden_fields.depth">
                    <b-form-group
                        :label="$t(locale.depthmm)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          name="login-email"
                          type="number"
                          min="1"
                          v-model="singleArticle.depth"
                          :placeholder="$t(locale.name)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="8" v-if="hidden_fields.weight">
                    <b-form-group
                        :label="$t(locale.weightGram)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          name="login-email"
                          v-model="singleArticle.weight"
                          :placeholder="$t(locale.name)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" v-if="hidden_fields.bulky">
                    <b-form-group :label="$t(locale.bulky)" label-for="input-1">
                      <LittleTabs
                          :tabs="[
                          {
                            label: 'CustomersCardEdit.yes',
                            active: true,
                            value: true,
                          },
                          {
                            label: 'CustomersCardEdit.no',
                            active: false,
                            value: false,
                          },
                        ]"
                          :defaultValue="true"
                          v-model="singleArticle.bulky"
                          buttonSize="sm"
                          containerStyle="justify-content-start"
                          btnClass="tabWrapperSmall2"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </SecondaryCard>
        </b-col>
      </b-row>
    </div>

    <b-row class="mt-5">
      <b-col cols="12" lg="6" class="d-flex smallGap justify-content-start">
        <b-button
            :variant="'danger'"
            v-text="'Delete Product'"
            @click="$bvModal.show('modal-open-remove-article')"
        />
      </b-col>
      <b-col cols="12" lg="6" class="d-flex smallGap justify-content-end">
        <b-button
            v-for="(btn, index) in buttonList"
            :key="index"
            :variant="btn.variant"
            @click="btn.click(btn)"
            :disabled="btn.processing"
        >
          <span>
            <span v-if="btn.submit">
              <span v-if="btn.processing">{{ $t("Message.loading") }}</span>
              <span v-else>{{ $t(btn.label) }}</span>
            </span>
            <span v-else>{{ $t(btn.label) }}</span>
          </span>
        </b-button>
      </b-col>
    </b-row>

    <Modal
        id="modal-open-remove-price"
        :hideFooter="true"
        :title="'Remove Price'"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          Är du säker på att du vill radera prislistan?
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-open-remove-price')"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="processingDelete"
            @click="deleteArticlePrice"
        >
          <span v-text="processingDelete ? $t('Message.loading') :  $t('Message.proceed')"/>
        </b-button>
      </div>
    </Modal>

    <Modal
        id="modal-open-remove-article"
        :hideFooter="true"
        :title="'Remove Product'"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          Är du säker på att du vill radera artikel
          {{ singleArticle.article_number }}?
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-open-remove-article')"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="processingDelete"
            @click="deleteArticle"
        >
          <span v-text="processingDelete ? $t('Message.loading') :  $t('Message.proceed')"/>
        </b-button>
      </div>
    </Modal>
    <Modal
        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied/>
    </Modal>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate";
import DatePicker from "vue2-datepicker";
import vSelect from "vue-select";
import debounce from "lodash.debounce";
import {articleObject} from "./constants/article";
import ArticlePriceRow from "./reuseable/ArticlePriceRow.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import bus from "@/bus";
import {mapGetters} from "vuex"
import accessDenied from "@core/components/permission/accessDenied";
import PAGES from "@/services/helpers/constant";

export default {
  name: "editArticleHolder",
  components: {
    vSelect,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
    ArticlePriceRow,
    accessDenied
  },
  data() {
    return {
      hidden_fields: {...JSON.parse(JSON.stringify(PAGES['accounting_data']['articles']))},
      config: useAppConfig(),
      tabSwitch: 1,
      currentComponent: "EditBasicArticleVue",
      tabs: [
        {
          label: "articleSectionCard.basic",
          component: "EditBasicArticleVue",
          active: true,
        },
        {
          label: "articleSectionCard.stockDetails",
          component: "EditStockArticleVue",
          active: false,
        },
      ],
      locale: {
        title: "articleSectionCard.createArticle",
        back: "articleSectionCard.backOverview",

        product: "articleSectionCard.productDetail",
        // price: "articleSectionCard.price",
        account: "articleSectionCard.accountDetails",
        // title: 'createContract.createContract',
        // backTo: 'golfRelatedCard.detail.backOverview',
        total: "invoicesCard.table.total",
        dltBtn: "CustomersCardEdit.dltBtn",
        stock: "articleSectionCard.stockDetails",

        price: "articleSectionCard.price",
        backTo: "golfRelatedCard.detail.backOverview",
        dltBtn: "CustomersCardEdit.dltBtn",

        itemID: "articleSectionCard.form.itemID",
        name: "articleSectionCard.form.name",
        orderPoint: "articleSectionCard.form.orderPoint",
        ean: "articleSectionCard.form.ean",
        manufacturer: "articleSectionCard.form.manufacturer",
        notes: "articleSectionCard.form.notes",
        supplier: "articleSectionCard.form.supplier",
        manufacturerNumber: "articleSectionCard.form.manufacturerNumber",
        active: "articleSectionCard.form.active",
        unit: "articleSectionCard.form.unit",
        stockItem: "articleSectionCard.form.stockItem",
        itemType: "articleSectionCard.form.itemType",
        externalWebshop: "articleSectionCard.form.externalWebshop",
        discontinue: "articleSectionCard.form.discontinue",
        salesAccount: "articleSectionCard.form.salesAccount",
        vat: "articleSectionCard.form.vat",
        salesSEReverseTax: "articleSectionCard.form.salesSEReverseTax",
        salesEUReverse: "articleSectionCard.form.salesEUReverse",
        salesEUVatLiable: "articleSectionCard.form.salesEUVatLiable",
        salesExport: "articleSectionCard.form.salesExport",
        purchaseAccount: "articleSectionCard.form.purchaseAccount",
        costCalculation: "articleSectionCard.form.costCalculation",
        inWarehouse: "articleSectionCard.form.inWarehouse",
        widthmm: "articleSectionCard.form.widthmm",
        heightmm: "articleSectionCard.form.heightmm",
        depthmm: "articleSectionCard.form.depthmm",
        stockValue: "articleSectionCard.form.stockValue",
        reserveQty: "articleSectionCard.form.reserveQty",
        stockPoint: "articleSectionCard.form.stockPoint",
        weightGram: "articleSectionCard.form.weightGram",
        bulky: "articleSectionCard.form.bulky",
        availableAmt: "articleSectionCard.form.availableAmt",
        purchasePrice: "articleSectionCard.form.purchasePrice",

        priceList: this.$t("articleSectionCard.table.priceList"),
        description: this.$t("articleSectionCard.table.description"),
        price: this.$t("articleSectionCard.table.price"), //
        margin: this.$t("articleSectionCard.table.margin"), // Discount %
      },
      fields: [
        {
          key: "priceList",
          label: this.$t("articleSectionCard.table.priceList"),
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 10px",
            width: "48px",
          },
        },
        {
          key: "description",
          label: this.$t("articleSectionCard.table.description"),
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "100px",
          },
        },

        {
          key: "price",
          label: this.$t("articleSectionCard.table.price"), //
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "50px",
          },
        },
        {
          key: "margin",
          label: this.$t("articleSectionCard.table.margin"), // Discount %
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "50px",
          },
        },
        {
          key: "id",
          label: "Action",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 10px",
            width: "48px",
          },
        },
      ],
      articlePrices: [],
      accountResults: [],
      pricelistResults: [],
      supplierResults: [],
      singleArticle: {...articleObject},
      unitResults: [],
      unitValue: "",
      articlePriceForm: {
        article_number: "",
        price_list: "",
        description: "",
        price: "",
        from_quantity: 0,
        margin: 0,
      },
      deleteArticlePriceId: {},
      priceAdding: false,
      processingDelete: false,

      buttonList: [
        {
          label: "UsersCard.cancel",
          value: "cancel",
          variant: "outline-secondary",
          submit: false,
          processing: false,
          click: this.cancelUpdate,
        },
        {
          label: "articleSectionCard.updateArticle",
          value: "save",
          variant: "primary",
          submit: true,
          processing: false,
          click: this.updateDataArticle,
        },
      ],
      margin: "",
      supplier: "",
      disableStock: true,
    };
  },
  watch: {
    "singleArticle.purchase_price": function (newVal, oldVal) {
      if (parseInt(newVal) || newVal === 0) {
        this.singleArticle.stock_value = parseFloat(
            this.singleArticle.purchase_price *
            this.singleArticle.quantity_in_stock
        ).toFixed(2);
      }
    },
    "singleArticle.quantity_in_stock": function (newVal, oldVal) {
      if (parseInt(newVal) || newVal === 0) {
        this.singleArticle.stock_value = parseFloat(
            this.singleArticle.purchase_price *
            this.singleArticle.quantity_in_stock
        ).toFixed(2);
      }
    },
    "articlePriceForm.price": function (n, o) {
      if (n) {
        this.computeMargin();
      }
    },
    "singleArticle.purchase_price": function (n, o) {
      if (n) {
        this.computeMargin();
      }
    },
    "singleArticle.type": function (n, o) {
      if (n == "STOCK") {
        this.singleArticle.stock_goods = true;
        this.disableStock = false;
      } else {
        this.disableStock = true;
      }

      if (n === "SERVICE") {
        this.singleArticle.stock_goods = false;
      }
    },
    "singleArticle.stock_goods": function (n, o) {
      if (n === true && this.singleArticle.type === "SERVICE") {
        this.singleArticle.stock_goods = false;
      }
    },
  },
  computed: {
    message() {
      return this.GET_LOCALE === "sv" ? "message" : "eng_message";
    },
    features() {
      if (this.getLang == "") {
        return this.swedishLang ? "features" : "features";
      } else return this.getLang == "sv" ? "features" : "features";
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  mounted() {
    const {id} = this.$route.params;
    this.getListData();
    if (id) {
      this.getSingleArticle(id);
    }
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    bus.$on("refetch", (_) => {
      this.getSingleArticle(id);
    });
    this.disabledFields()
  },
  methods: {
    cancelUpdate() {
      this.$router.go(-1);
    },
    switchTabs(tabIndex) {
      if (!this.disableStock) {
        this.tabs.find((item) => item.active == true).active = false;

        this.currentComponent = this.tabs[tabIndex].component;
        this.tabSwitch =
            this.tabs[tabIndex].component === "EditStockArticleVue" ? 2 : 1;

        this.tabs[tabIndex].active = true;
      }
    },
    async getListData() {
      const supplier = await this.$useJwt.getSuppliers();
      const units = await this.$useJwt.getAllRegisters("units");
      this.unitResults = units.data.results.map((el) => {
        return {label: el.description, value: el.code};
      });
      this.supplierResults = supplier.data.results.map((el) => {
        return {label: el.name, value: el.supplier_number};
      });
    },
    getSingleArticle(id) {
      this.$useJwt
          .customArticles({URL: `/${id}`, method: "get"})
          .then((res) => {
            this.singleArticle = res.data.data;
            this.articlePriceForm.article_number =
                this.singleArticle.article_number;
            this.disableStock =
                this.singleArticle.type === "STOCK" ? false : true;
            this.unitValue = this.singleArticle.unit;
            this.supplier = this.singleArticle.supplier_number;
            this.getArticlePrice(this.singleArticle.article_number);
          }).catch(err => {
        if (err.response.status === 403) {
          window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }
      });
    },
    openAccessDeniedModal() {
      this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
      this.$bvModal.show("modal-access-denied");

    },
    getArticlePrice(payload) {
      const mode = {
        URL: `/prices/?article__article_number=${payload}`,
        method: "get",
      };
      this.$useJwt.customArticles(mode).then((res) => {
        this.articlePrices = res.data.data.results;
        if (this.articlePrices.length === 0) {
          this.articlePriceForm.price = this.singleArticle.sales_price;
          if (this.singleArticle.purchase_price) {
            this.articlePriceForm.margin =
                this.singleArticle.sales_price -
                this.singleArticle.purchase_price;
          }
        }
      });
    },
    computeMargin() {
      let margin =
          this.articlePriceForm.price - this.singleArticle.purchase_price;
      if (!isNaN(margin)) {
        this.margin = margin;
      } else {
        this.margin = "-";
      }
    },
    updateUnitCode(event) {
      this.singleArticle.unit = event.value;
    },
    searchAccounts(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteAccounts(loading, search, this);
      }
    },
    searchRemoteAccounts: debounce(function (loading, search, vm) {
      this.$useJwt
          .getAllRegisters(`accounts?search=${escape(search)}`)
          .then((res) => {
            this.accountResults = res.data.results;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    changeAccounts(account, key) {
      this.singleArticle[key] = account.number;
    },
    computeSelect(item, value) {
      this.articlePriceForm[value] = item.value;
    },
    computeSupplier(item, value) {
      this.singleArticle[value] = item.value;
      this.singleArticle.supplier_name = item.label;
    },
    searchPricelist(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemotePricelist(loading, search, this);
      }
    },
    searchRemotePricelist: debounce(function (loading, search, vm) {
      this.$useJwt
          .getAllRegisters(`pricelists?search=${escape(search)}`)
          .then((res) => {
            this.pricelistResults = res.data.results;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    changePricelist(item) {
      this.articlePriceForm.price_list = item.code;
      this.articlePriceForm.description = item.description;
      // if (item.pre_selected) {
      //   this.articlePriceForm.price_list = item.code;
      //   this.articlePriceForm.description = item.description;
      // } else {
      //   this.popupMsg(
      //       this.$t("Message.Failed"),
      //       "Only preselected are required",
      //       "AlertTriangleIcon",
      //       "danger"
      //   );
      //   this.articlePriceForm.price_list = "";
      // }
    },
    updateDataArticle(item) {
      this.updateArticle(item);
    },
    deleteArticle() {
      // if (this.CURRENT_USER.access === "Read") {
      //     return this.$toast.info(this.$t("limited_read_access"));
      // }
      this.processingDelete = true;

      const mode = {URL: `/${this.singleArticle.uuid}`, method: "delete"};

      this.$useJwt
          .customArticles(mode)
          .then((res) => {
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
            this.$router.go(-1);
          })
          .catch((err) => {
            this.processingDelete = false;
            if (
                err.response.data &&
                err.response.data &&
                err.response.data.message
            ) {
              if (err.response.data.message.ErrorInformation) {
                this.popupMsg(
                    this.$t("Message.Failed"),
                    err.response.data.message.ErrorInformation.message,
                    "AlertTriangleIcon",
                    "danger"
                );
              } else {
                this.popupMsg(
                    this.$t("Message.Failed"),
                    err.response.data.message,
                    "AlertTriangleIcon",
                    "danger"
                );
              }
            } else {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            }
          });
    },
    deleteArticlePrice() {
      this.processingDelete = true;
      const mode = {
        URL: `/prices/${this.deleteArticlePriceId.uuid}`,
        method: "delete",
      };

      this.$useJwt
          .customArticles(mode)
          .then((res) => {
            this.processingDelete = false;
            this.getArticlePrice(this.singleArticle.article_number);

            this.removePriceField();
            this.articlePriceForm.description = "";
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            this.processingDelete = false;
            if (
                err.response.data &&
                err.response.data &&
                err.response.data.message
            ) {
              if (err.response.data.message.ErrorInformation) {
                this.popupMsg(
                    this.$t("Message.Failed"),
                    err.response.data.message.ErrorInformation.message,
                    "AlertTriangleIcon",
                    "danger"
                );
              } else {
                this.popupMsg(
                    this.$t("Message.Failed"),
                    err.response.data.message,
                    "AlertTriangleIcon",
                    "danger"
                );
              }
            } else {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            }
          });
    },
    updateArticle(buttonItem) {
      // if (this.CURRENT_USER.access === "Read") {
      //     return this.$toast.info(this.$t("limited_read_access"));
      // }
      let exclude = [];
      let data_to_save = {};
      let articleData = this.singleArticle;

      // for (const [key, value] of Object.entries(articleData)) {
      //   if (!exclude.includes(key)) {
      //     if (value !== "" && value !== null && value !== undefined) {
      //       data_to_save[key] = value;
      //     }
      //   }
      // }
      if (!this.unitValue) {
        articleData['unit'] = null
      }
      buttonItem.processing = true;
      const mode = {URL: `/${this.singleArticle.uuid}`, method: "put"};

      this.$useJwt
          .customArticles(mode, articleData)
          .then((res) => {
            buttonItem.processing = false;
            bus.$emit("clear-row", {});

            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            buttonItem.processing = false;
            if (
                err.response.data &&
                err.response.data &&
                err.response.data.message
            ) {
              if (err.response.data.message.ErrorInformation) {
                this.popupMsg(
                    this.$t("Message.Failed"),
                    err.response.data.message.ErrorInformation.message,
                    "AlertTriangleIcon",
                    "danger"
                );
              } else {
                this.popupMsg(
                    this.$t("Message.Failed"),
                    err.response.data.message,
                    "AlertTriangleIcon",
                    "danger"
                );
              }
            } else {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            }
          });
    },

    removeArticlePrice(item) {
      this.deleteArticlePriceId = item;
      this.$bvModal.show("modal-open-remove-price");
    },

    addArticlePrice() {
      // if (this.CURRENT_USER.access === "Read") {
      //     return this.$toast.info(this.$t("limited_read_access"));
      // }
      if (
          this.articlePriceForm.article_number === "" ||
          this.articlePriceForm.price_list === "" ||
          this.articlePriceForm.price === ""
      ) {
        this.popupMsg(
            this.$t("Message.Failed"),
            "Artikelnr., prislista and pris är obligatoriskt",
            "AlertTriangleIcon",
            "danger"
        );
      } else {
        this.priceAdding = true;

        const mode = {URL: "/prices/", method: "post"};

        this.$useJwt
            .customArticles(mode, this.articlePriceForm)
            .then((res) => {
              this.priceAdding = false;
              this.getArticlePrice(this.singleArticle.article_number);

              this.removePriceField();
              this.articlePriceForm.description = "";
              this.popupMsg(
                  this.$t("Message.Success"),
                  res.data[this.message],
                  "CheckIcon",
                  "success"
              );
            })
            .catch((err) => {
              this.priceAdding = false;
              if (
                  err.response.data &&
                  err.response.data &&
                  err.response.data.message
              ) {
                if (err.response.data.message.ErrorInformation) {
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data.message.ErrorInformation.message,
                      "AlertTriangleIcon",
                      "danger"
                  );
                } else {
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data.message,
                      "AlertTriangleIcon",
                      "danger"
                  );
                }
              } else {
                this.popupMsg(
                    this.$t("Message.Failed"),
                    err.response.data[this.message],
                    "AlertTriangleIcon",
                    "danger"
                );
              }
            });
      }
    },
    removePriceField() {
      this.articlePriceForm.price = this.singleArticle.sales_price;
      this.articlePriceForm.from_quantity = 1;
      this.articlePriceForm.dispatch = "";
      this.articlePriceForm.price_list = "";
    },
    disabledFields() {
      let hidden_object = this.hiddenResource
      try {
        // this.$useJwt.hiddenResource({method: 'get', URL: `/${hidden_object['id']}/`}, {}).then()
        hidden_object['hidden_fields']['accounting_data']['articles'].map(item => {
          this.hidden_fields[item] = false
        })
      } catch (e) {
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.table th {
  padding: 0.3rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table tbody,
td {
  padding: 5px !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}
</style>
